<template>
  <body-layout :style="backCss" :isUseBodyLayoutStyle="isUseBodyLayoutStyle" :title="title" v-has="viewPermissionString">
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="deletePermissionString"
        >删除</a-button
      >
      <a-button
        type="primary"
        class="mr-3"
        @click="pSync"
        v-if="selectedRowKeys.length"
        :disabled="selectedRowKeys.length >= 2"
        icon="cloud"
        v-has="editPermissionString"
        >同步</a-button
      >
      <a-button type="primary" @click="openAddForm" v-has="addPermissionString">{{ btnTitle || '新建' }}</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px" v-if="false">
        <i class="anticon anticon-info-circle ant-alert-icon"></i>已选择<a class="mx-1 text-bold">{{
          selectedRowKeys.length
        }}</a
        >项&nbsp;&nbsp;
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <!-- <a-table  v-has="viewPermissionString" -->
      <a-table
        style="border-top:1px solid rgb(232,232,232)"
        ref="table"
        size="middle"
        rowKey="id"
        :columns="
          columns.filter(item => {
            return propsFilters.typeId == 4 || item.dataIndex != 'platforms'
          })
        "
        :dataSource="dataSource"
        :customRow="clickRow"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{
          hideDefaultSelections: false,
          selectedRowKeys: selectedRowKeys,
          onChange: false ? onPreviewSelectChange : onSelectChange,
          columnWidth: isDetail ? 61 : 51
        }"
        @change="handleTableChange"
        :scroll="{ x: '1200px', y: 'calc(100vh - 405px)' }"
      >
        <span slot="classId" slot-scope="text, record">
          <label v-for="item in configFormData.classIds" :key="item.value">
            <span v-if="item.value === text">
              {{ item.label }}
            </span>
          </label>
        </span>
        <span slot="guideName" slot-scope="text, record">
          <span class="guide-name" v-if="propsFilters.typeId == 2||propsFilters.typeId == 3" @click.stop="guideNameClick(record)">{{
            record.guideName
          }}</span>
          <span v-else>{{ record.guideName }}</span>
        </span>
        <span slot="typeId" slot-scope="text, record">
          <div v-if="!propsFilters.typeId || isDefaultTypeId">
            <label v-for="item in configFormData.typeIds" :key="item.value">
              <span v-if="item.value === text">
                {{ item.label }}
              </span>
            </label>
          </div>
        </span>

        <span slot="departmentNames" slot-scope="text, record">
          <div>
            <a-tag v-for="one in record.departments" :key="one.departId">
              {{ one.departName }}
            </a-tag>
          </div>
        </span>
        <span slot="platforms" slot-scope="text, record">
          <div>
            <a-tag v-for="one in record.platforms" :key="one.platformId">
              {{ one.platformName }}
            </a-tag>
          </div>
        </span>

        <span slot="positionNames" slot-scope="text, record">
          <div v-if="propsFilters.typeId != 1">
            <a-tag v-for="one in record.positions" :key="one.positionId">
              {{ one.positionName }}
            </a-tag>
          </div>
          <div v-else>
            <a-tag v-for="one in record.positions" :key="one.positionId"
              >{{ one.positionName + '-' }}{{ one.type == 'VIEW' ? '查看' : '编辑' }}</a-tag
            >
          </div>
        </span>
      </a-table>
    </div>
    <!-- table区域-end -->
    <guide-modal
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
      :permission="editPermissionString"
      :propsUrl="url"
      :productionId="productionId"
      :platformId="platformId"
      :departmentId="propsDepartmentId"
      :props-filters="propsFilters"
      :is-preview="false"
      :isMaskClosable="isMaskClosable"
      :hide-edit-btn="false"
      @onConfirm="onConfirm"
    ></guide-modal>
    <sync-modal
      ref="syncModal"
      url="/rule/sync"
      :selectedRowKeys="selectedRowKeys"
      :selectionRows="selectionRows"
      idType="ruleIds"
    />
  </body-layout>
</template>

<script>
import GuideModal from './guide-modal'
import syncModal from '@/components/sync-modal'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import JInput from '@/components/jeecg/JInput'
import { configFormData } from './config'
import { getAction } from '@/api/manage'
export default {
  name: 'guide',
  mixins: [JeecgListMixin],
  components: {
    GuideModal,
    JInput,
    syncModal
  },
  props: {
    isDetail: {
      type: Boolean,
      default: () => false
    },
    addPermissionString: {
      type: String,
      default: ''
    },
    editPermissionString: {
      type: String,
      default: ''
    },
    deletePermissionString: {
      type: String,
      default: ''
    },
    viewPermissionString: {
      type: String,
      default: ''
    },
    title: '',
    btnTitle: '',
    isDefaultTypeId: {
      type: Boolean,
      default: () => false
    },
    isPreview: {
      type: Boolean,
      default: () => false
    },
    isMaskClosable: {
      type: Boolean,
      default: () => false
    },
    hideEditBtn: {
      type: Boolean,
      default: () => false
    },
    propsUrl: {
      type: Object,
      default: () => ({
        list: '/guide/list',
        delete: '/guide/delete',
        deleteBatch: '/guide/delete_batch',
        edit: '/guide/edit',
        add: '/guide/add'
      })
    },
    propsProductionId: {
      type: [String, Number],
      default: ''
    },
    propsPlatformId: {
      type: [String, Number],
      default: ''
    },
    propsDepartmentId: {
      type: String,
      default: ''
    },
    propsFilters: {
      type: Object,
      default: () => ({})
    },
    propsIpagination: {
      current: 1,
      pageSize: 10,
      pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
      showTotal: (total, range) => {
        return range[0] + '-' + range[1] + ' 共' + total + '条'
      },
      showQuickJumper: true,
      showSizeChanger: true,
      total: 0
    },
    isUseBodyLayoutStyleProps: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      configFormData,
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: this.isDetail ? 60 : 50
        },
        {
          title: '名称',
          dataIndex: 'guideName',
          scopedSlots: { customRender: 'guideName' },
          width: 170
        },
        {
          title: '分类',
          dataIndex: 'typeId',
          scopedSlots: { customRender: 'typeId' },
          width: 180
        },
        {
          title: '属性',
          dataIndex: 'classId',
          scopedSlots: { customRender: 'classId' },
          width: 120
        },
        {
          title: '适用部门',
          scopedSlots: { customRender: 'departmentNames' },
          dataIndex: 'departments',
          width: 280,
          colSpan: this.isDetail && 0
        },
        {
          title: '适用平台',
          scopedSlots: { customRender: 'platforms' },
          dataIndex: 'platforms',
          width: 180,
          colSpan: this.isDetail && 0
        },
        {
          title: '适用职位',
          scopedSlots: { customRender: 'positionNames' },
          dataIndex: 'positions',
          width: 180,
          colSpan: this.isDetail && 0
        }
      ],
      url: this.propsUrl,
      productionId: this.propsProductionId,
      platformId: this.propsPlatformId,
      id: this.propsProductionId || this.propsPlatformId,
      isUseBodyLayoutStyle: this.isUseBodyLayoutStyleProps,
      disableMixinCreated: true,
      allPlatformList: [],
      color:''
    }
  },
  created() {
    console.log({ 'this.propsFilters': this.propsFilters })
    if (this.propsFilters.typeId) {
      this.columns = this.columns.filter(({ dataIndex }) => dataIndex != 'typeId')
    }
    this.isDefaultTypeId && (this.propsFilters.typeId = '1')
    this.filters = { ...this.filters, ...this.propsFilters }
    console.log({ 'this.filters': this.filters })
    this.loadData()
    //初始化字典配置 在自己页面定义
    this.initDictConfig()
    this.getAllPlatform()
  },
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color
      }
    },
  },
  methods: {
    guideNameClick(record) {
      window.open(record.guideData, 'black')
    },
    getAllPlatform() {
      getAction('/platform/list?pageSize=1000').then(res => {
        if (res.success) {
          this.allPlatformList = res.data.records
        }
      })
    },
    handleSelectChange(val) {
      this.classId = val
    },
    onPreviewSelectChange(selectedRowKeys, selectionRows) {
      console.log(selectedRowKeys, selectionRows, 'onPreviewSelectChange')
      // this.selectedRowKeys = selectedRowKeys
      // this.selectionRows = selectionRows
    },
    onConfirm(id) {
      console.log('guide-base onConfirm', { id })
      this.selectedRowKeys = [...this.selectedRowKeys, id]
      console.log('onConfirm', { id, 'this.dataSource': this.dataSource, 'this.selectedRowKeys': this.selectedRowKeys })
      let isAllConfirm = this.dataSource.map(({ id }) => id).every(id => this.selectedRowKeys.includes(id))
      if (isAllConfirm) {
        console.log('全部勾选了!!!')
        this.$emit('tableConfirm', this.propsFilters)
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@assets/less/common.less';
/deep/ .border-bottom {
  border: none;
}
/deep/ .ant-table-row-cell-break-word {
  border-top: 1px solid #e8e8e8 !important;
}
.guide-name{
  cursor: pointer;
  &:hover{
    color:var(--theme-color)
  }
}
</style>
